import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

export interface SearchProductsHeaderProps {
  className?: string
  anyItemsReturned: boolean
  loading: boolean
  search?: { keywords?: string } | null
}

const SearchProductsHeader: FC<SearchProductsHeaderProps> = ({
  className,
  anyItemsReturned,
  loading,
  search,
}) => {
  const previousSearchValue = search?.keywords || ''
  const [searchInput, setSearchInput] = useState(previousSearchValue)

  // Update search input if it changes from either the url or the header search bar
  useEffect(() => {
    setSearchInput(search?.keywords || '')
  }, [search])

  return (
    <header className={className}>
      {loading ? (
        <div className="placeholder">
          <h2>{searchInput ? `Searching for "${searchInput}"` : 'Searching for all products'}</h2>
        </div>
      ) : (
        <div>
          <h2>
            {anyItemsReturned ? (
              previousSearchValue ? (
                <>Showing results for &quot;{previousSearchValue}&quot;</>
              ) : (
                'Showing results for all products'
              )
            ) : (
              <>We couldn&apos;t find results for &quot;{previousSearchValue}&quot;</>
            )}
          </h2>
        </div>
      )}
    </header>
  )
}

SearchProductsHeader.defaultProps = {
  className: '',
  search: null,
}

const StyledSearchProductsHeader = styled(SearchProductsHeader)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.coolGray.cool150,
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 20px',
  textAlign: 'center',
  h2: {
    marginBottom: '0',
    fontSize: '16px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '400',
  },
  '.placeholder': {
    minHeight: '48px',
  },
}))

export default StyledSearchProductsHeader
