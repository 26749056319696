import { Location, LocationContext } from '@gatsbyjs/reach-router'
import queryString, { ParsedQuery } from 'query-string'
import React, { FC } from 'react'

import { EmptyObject } from '../utils/types'

export type WithLocationProps<TProps> = TProps & LocationContext & { search?: ParsedQuery | null }
export type WithLocationFC<TProps> = FC<WithLocationProps<TProps>>

const withLocation = <TProps = EmptyObject,>(
  ComponentToWrap: (props: WithLocationProps<TProps>) => JSX.Element | null,
): ((props: TProps) => JSX.Element | null) => {
  const LocationHOC = (props: TProps) => {
    return (
      <Location>
        {({ location, navigate }) => (
          <ComponentToWrap
            {...props}
            location={location}
            navigate={navigate}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        )}
      </Location>
    )
  }
  return LocationHOC
}

export default withLocation
